import React, { useEffect } from "react"
import { navigate } from "gatsby"

const NetworkAppsRedirectionPage = () => {
  useEffect(() => {
    navigate("/solutions/network/app-delivery-network")
  }, [])

  return <></>
}

export default NetworkAppsRedirectionPage
